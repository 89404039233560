import React from "react";
import styled from "styled-components";

const Flex = props => {
  return <StyledFlex {...props}>{props.children}</StyledFlex>;
};

const StyledFlex = styled.div`
  display: flex;
  height: ${({ height }) => height};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  margin-bottom: ${({ mBot }) => mBot};
`;

export default Flex;

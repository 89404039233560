import React from "react";
import styled, { keyframes } from "styled-components";
import { Container, Page, Flex } from "../layout";
import { HomeButton } from "../utility";
import {
  HTMLIcon,
  CSSIcon,
  JavaScriptIcon,
  ReactIcon,
  SassIcon,
  WordPressIcon,
} from "../../images/icons";
import TooltipImage from "../utility/TooltipImage";
import { useLocation } from "react-router-dom";
// import Portrait from "../../images/portrait.jpg";

const About = () => {
  const { state } = useLocation();

  const highlight = state && state.highlight ? true : false;

  return (
    <AboutPage pageTitle="About Me | Connor Biddle">
      <Container className="about-container" narrowOnMobile>
        <HomeButton />
        <div className="about-content">
          <div className="about-text">
            <h2 className="about-title">About me</h2>
            <p>
              I'm Connor Biddle, a self-taught front-end web developer based in
              Oldbury, West Midlands, with 3+ years' experience in writing code.
              I've got a keen eye for visuals and can turn wireframes to code
              with pinpoint precision - or design websites myself. I love
              working with React.
            </p>
            <p>
              When I'm not writing code, I'm usually playing guitar or listening
              to music.
            </p>
            <p className={highlight ? "highlight" : undefined}>
              Get in touch with me on{" "}
              <a
                href="https://www.linkedin.com/in/connor-biddle"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
              , see what I'm up to on{" "}
              <a
                href="https://github.com/connorbiddle"
                target="_blank"
                rel="noreferrer"
              >
                GitHub
              </a>
              , or send me an{" "}
              <a href="mailto:connor.biddle@hotmail.com">email</a>.{" "}
            </p>
            <h2 className="about-subtitle">Top skills</h2>
            <Flex className="about-icons" alignItems="center">
              <TooltipImage src={HTMLIcon} alt="" tooltip="HTML5" />
              <TooltipImage src={CSSIcon} alt="" tooltip="CSS3" />
              <TooltipImage src={JavaScriptIcon} alt="" tooltip="JavaScript" />
              <TooltipImage src={ReactIcon} alt="" tooltip="React" />
              <TooltipImage src={SassIcon} alt="" tooltip="Sass" />
              <TooltipImage src={WordPressIcon} alt="" tooltip="WordPress" />
            </Flex>
          </div>
          <figure className="about-image">
            {/* <img src={Portrait} alt="" /> */}
          </figure>
        </div>
      </Container>
    </AboutPage>
  );
};

const Highlight = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0.5rem);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(0.5rem);
  }
  100% {
    transform: translateX(0);
  }
`;

const AboutPage = styled(Page)`
  .about-container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
  }

  .about-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    .about-title {
      text-align: center;
    }

    .about-subtitle {
      margin-top: 3rem;
      margin-bottom: 1.5rem;
      text-align: center;
    }

    .about-text {
      margin-bottom: 2rem;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      .highlight {
        animation: ${Highlight} 1250ms ease-in-out 500ms;
        transform-origin: left center;
      }
      .about-subtitle {
        margin-top: 3rem;
        margin-bottom: 1.5rem;
        text-align: left;
      }
    }

    .highlight-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .about-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      flex-wrap: wrap;

      figure {
        width: 2rem;
        height: auto;
        margin-right: 1rem;
        margin-bottom: 1rem;
      }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .about-title {
        text-align: left;
      }

      .about-text {
        flex: 3;
        padding-right: 2rem;
        margin-bottom: 0;
      }

      .about-image {
        flex: 1;
        padding-left: 2rem;
      }

      .about-icons {
        justify-content: flex-start;
      }
    }
  }
`;

export default About;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalStyle from "./styles/GlobalStyle";
import Theme from "./styles/Theme";
import App from "./App";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

ReactDOM.render(
  <React.StrictMode>
    <Theme>
      <GlobalStyle />
      <Router>
        <App />
      </Router>
    </Theme>
  </React.StrictMode>,
  document.getElementById("root")
);

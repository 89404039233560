import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    primary: "#D14040",
    white: "#FFFFFF",
    black: "#111111",
    grey: "#8A8A8A",
  },
  breakpoints: {
    sm: "500px",
    md: "768px",
    lg: "1000px",
  },
};

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;

import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import styled from "styled-components";
import Container from "../layout/Container";

const Navbar = () => {
  return (
    <StyledNavbar>
      <Container className="nav-contents">
        <h1 className="nav-title">Connor Biddle</h1>
        <div>
          <ScrollLink
            className="nav-link"
            to="projects"
            smooth="true"
            duration={750}
            offset={-window.innerWidth * 0.1}
          >
            Projects
          </ScrollLink>
          <Link className="nav-link" to="/about">
            About
          </Link>
        </div>
      </Container>
    </StyledNavbar>
  );
};

const StyledNavbar = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  z-index: 100;

  .nav-contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .nav-title {
    font-size: calc(1.25rem + 0.5vw);
    margin-bottom: 0;
  }

  .nav-link {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    font-size: calc(0.85rem + 0.4vw);
    margin-left: calc(0.75rem + 1.25vw);
  }
`;

export default Navbar;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import Container from "../layout/Container";
import Button from "../utility/Button";
import Animate from "../utility/Animate";
import Background from "../../images/showcase-bg.jpg";
import Cover from "../../images/showcase-cover.svg";

const Showcase = () => {
  return (
    <StyledShowcase>
      <Container className="showcase-contents">
        <Animate className="showcase-title-animate" delay={500} duration={850}>
          <h2 className="showcase-title">
            I'm a front-end developer with a focus on JavaScript and React.
          </h2>
        </Animate>
        <Animate delay={850} duration={500}>
          <div className="showcase-buttons">
            <Button
              type="light"
              as={ScrollLink}
              to="projects"
              smooth="true"
              duration={750}
              offset={-window.innerWidth * 0.1}
            >
              See My Work
            </Button>
            <Button
              type="outline"
              as={Link}
              to={{
                pathname: "/about",
                state: { highlight: true },
              }}
            >
              Get In Touch
            </Button>
          </div>
        </Animate>
      </Container>
    </StyledShowcase>
  );
};

const StyledShowcase = styled.section`
  color: ${({ theme }) => theme.colors.white};
  background: #d14040;
  background: url(${Background}) center center / cover no-repeat;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  position: relative;
  margin-bottom: calc(3.5rem + 5vw);

  .showcase-contents {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: calc(550px + 12.5vw);
  }

  .showcase-title {
    font-size: calc(1.25rem + 1.2vw);
    font-weight: 700;
    line-height: 1.5;
    max-width: 850px;
    text-align: center;
  }

  .showcase-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    > a {
      margin-bottom: 1rem;
      font-size: calc(0.9rem + 0.3vw);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .showcase-title {
      text-align: left;
    }

    .showcase-buttons {
      flex-direction: row;

      > a {
        margin-right: 1rem;
        margin-bottom: 0;
      }
    }
  }

  &::after {
    content: url(${Cover});
    position: absolute;
    bottom: calc(-5px - 0.15vw);
    width: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }
`;

export default Showcase;

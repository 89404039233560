import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

const Gallery = ({ sources, mBot }) => {
  const sliderConfig = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 600,
  };

  return (
    <StyledGallery mBot={mBot}>
      {sources.length > 1 ? (
        <Slider {...sliderConfig}>
          {sources.map(source => (
            <div key={source}>
              <img src={source} alt="" />
            </div>
          ))}
        </Slider>
      ) : (
        <img src={sources[0]} alt="" />
      )}
    </StyledGallery>
  );
};

const StyledGallery = styled.div`
  margin-bottom: ${({ mBot }) => (mBot ? mBot : 0)};
  border: 1px solid #ddd;

  img {
    margin-bottom: -4px;
  }
`;

export default Gallery;

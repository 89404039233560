import React from "react";
import styled from "styled-components";

const TooltipImage = props => {
  return (
    <StyledTooltip {...props}>
      <img src={props.src} alt={props.alt || ""} />
      <figcaption>{props.tooltip}</figcaption>
    </StyledTooltip>
  );
};

const StyledTooltip = styled.figure`
  position: relative;

  figcaption {
    font-size: 1rem;
    position: absolute;
    pointer-events: none;
    top: calc(100% + 0.5rem);
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    padding: 0.25em 0.4em;
    border-radius: 5px;

    opacity: 0;
    transform: translate(-50%, 0.4rem);
    transition: opacity 350ms ease, transform 350ms ease;
  }

  &:hover figcaption {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

export default TooltipImage;

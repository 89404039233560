import React from "react";
import styled, { keyframes } from "styled-components";

const Animate = props => {
  return (
    <StyledAnimate {...props}>
      <div>{props.children}</div>
    </StyledAnimate>
  );
};

const SlideUpAnimation = keyframes`
  0% {
    transform: translateY(calc(100% + 3px));
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
`;

const StyledAnimate = styled.div`
  overflow-y: hidden;

  > div {
    opacity: 0;
    transform: translateY(calc(100% + 5px));
    animation: ${SlideUpAnimation} 750ms ease forwards 0ms;
    animation-duration: ${({ duration }) => `${duration}ms` || "750ms"};
    animation-delay: ${({ delay }) => `${delay}ms` || "0ms"};
  }
`;

export default Animate;

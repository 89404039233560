import React from "react";
import { Link } from "react-router-dom";
import { Button, TooltipImage } from "../utility";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import styled from "styled-components";

const ProjectPreview = ({ project }) => {
  const { title, description, technologies, thumbnail, slug } = project.fields;
  const descriptionHtml = documentToHtmlString(description);

  return (
    <StyledProjectPreview>
      <div className="project-text">
        <div className="title-row">
          <h2 className="project-title">{title}</h2>
          {technologies && (
            <div className="project-technologies">
              {technologies.map(t => (
                <TooltipImage
                  key={t.sys.id}
                  src={t.fields.file.url}
                  alt={t.fields.title}
                  tooltip={t.fields.title}
                />
              ))}
            </div>
          )}
        </div>
        <div
          className="project-description"
          dangerouslySetInnerHTML={{ __html: descriptionHtml }}
        />
        <Button as={Link} to={`/projects/${slug}`} className="project-link">
          View Project
        </Button>
      </div>
      <figure className="project-image">
        <img src={thumbnail.fields.file.url} alt="" />
      </figure>
    </StyledProjectPreview>
  );
};

const StyledProjectPreview = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
  font-size: calc(1.1rem + 0.25vw);

  .title-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0.25rem;
  }

  .project-title {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .project-technologies {
    height: calc(1.1rem + 0.25vw);
    display: flex;
    align-items: center;
    margin: 0.75rem 0;

    figure {
      height: 1.5rem;
      width: auto;
      margin-right: 0.5rem;
    }

    figure img {
      height: 100%;
    }
  }

  .project-description {
    margin-bottom: 1.25rem;
  }

  .project-text {
    order: 2;
  }

  .project-image {
    order: 1;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #e0e0e0;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .project-text {
      margin-right: 2rem;
      order: 1;
    }

    .project-image {
      flex-shrink: 0;
      flex-basis: 350px;
      order: 2;
      box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);
      border: none;
    }
  }
`;

export default ProjectPreview;

import React from "react";
import { Showcase, Navbar, Projects } from "../sections";
import { Page } from "../layout";

const Home = ({ projects }) => {
  return (
    <Page pageTitle="Connor Biddle | Front-End Web Developer">
      <Navbar />
      <Showcase />
      <Projects projects={projects} />
    </Page>
  );
};

export default Home;

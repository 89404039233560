import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Footer } from "../sections";

const Page = props => {
  const duration = 300;

  return (
    <StyledPage d={duration} {...props}>
      {props.pageTitle && (
        <Helmet>
          <title>{props.pageTitle}</title>
        </Helmet>
      )}
      {props.children}
      <Footer />
    </StyledPage>
  );
};

const StyledPage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100.5vh;
  display: flex;
  flex-direction: column;

  &.page-transition-appear,
  &.page-transition-enter {
    position: fixed;
    opacity: 0;
    z-index: 1;
  }

  &.page-transition-appear-active,
  &.page-transition-enter.page-transition-enter-active {
    opacity: 1;
    transition: opacity ${({ d }) => d}ms ease-out ${({ d }) => d / 2}ms;
  }

  &.page-transition-exit {
    opacity: 1;
  }

  &.page-transition-exit.page-transition-exit-active {
    opacity: 0;
    transition: opacity ${({ d }) => d / 2}ms ease-out;
  }
`;

export default Page;

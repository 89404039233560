import React from "react";
import { ExternalLink, GitHub } from "react-feather";
import { Button, Gallery } from "../utility";
import Flex from "../layout/Flex";
import styled from "styled-components";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import TooltipImage from "../utility/TooltipImage";

const ProjectContent = ({ project }) => {
  const { title, contentBody, technologies, liveUrl, githubUrl, images } =
    project.fields;
  const bodyHtml = documentToHtmlString(contentBody);
  const imageUrls = images?.map(i => i.fields.file.url);

  console.log(githubUrl);

  return (
    <StyledProjectContent>
      <h2 className="project-title">{title}</h2>
      <Flex
        className="project-technologies"
        alignItems="center"
        justifyContent="center"
      >
        {technologies.map(t => (
          <TooltipImage
            key={t.sys.id}
            src={t.fields.file.url}
            tooltip={t.fields.title}
            alt={t.fields.title}
          />
        ))}
      </Flex>
      <div
        className="project-body"
        dangerouslySetInnerHTML={{ __html: bodyHtml }}
      />
      {(liveUrl || githubUrl) && (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          mBot="3rem"
        >
          {liveUrl && (
            <Button
              as="a"
              target="_blank"
              href={liveUrl}
              icon={ExternalLink}
              mBot="1rem"
              mX="0.5rem"
            >
              Live Project
            </Button>
          )}
          {githubUrl && (
            <Button
              as="a"
              target="_blank"
              href={githubUrl}
              icon={GitHub}
              mBot="1rem"
              mX="0.5rem"
            >
              Source Code
            </Button>
          )}
        </Flex>
      )}
      {images && (
        <Gallery className="project-gallery" sources={imageUrls} mBot="6rem" />
      )}
    </StyledProjectContent>
  );
};

const StyledProjectContent = styled.section`
  .project-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  .project-technologies {
    margin-bottom: 2.5rem;

    > figure {
      width: 1.75rem;
      margin: 0 0.5rem;
    }
  }

  .project-body {
    margin-bottom: 2.5rem;
    overflow-wrap: break-word;
    font-size: 1.1rem;

    p {
      margin-bottom: 1rem;
    }
  }
`;

export default ProjectContent;

import { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Home, Project, About } from "./components/pages";
import client from "./lib/contentful";

const App = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    client.getEntries().then(entries => {
      const projects = entries.items.filter(
        e => e.sys.contentType.sys.id === "project"
      );

      setProjects(projects);
    });
  }, []);

  const onPageEntered = () => window.scrollTo(0, 0);

  return (
    <div className="App">
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={400}
              classNames="page-transition"
              onEntered={onPageEntered}
            >
              <Switch location={location}>
                <Route
                  exact
                  path="/"
                  render={() => <Home projects={projects} />}
                />
                <Route exact path="/about" render={() => <About />} />
                <Route
                  exact
                  path="/projects/:slug"
                  render={rp => (
                    <Project
                      project={projects.find(
                        p => p.fields.slug === rp.match.params.slug
                      )}
                    />
                  )}
                />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </div>
  );
};

export default App;

import React from "react";
import styled, { css } from "styled-components";

const Button = props => {
  const Icon = props.icon;

  return (
    <StyledButton {...props}>
      {props.icon && <Icon size={"1em"} />}
      {props.children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 0.85em 1.5em;
  border-radius: 100em;
  border: none;
  transition: background-color 250ms ease, color 250ms ease;
  text-decoration: none;
  font-size: ${({ fontSize }) => fontSize || "1.1rem"};
  margin-bottom: ${({ mBot }) => mBot || 0};

  ${({ mX }) =>
    mX &&
    css`
      margin-right: ${mX};
      margin-left: ${mX};
    `}

  > svg {
    margin-right: 0.25em;
  }

  ${({ type }) => {
    switch (type) {
      case "outline":
        return css`
          background-color: transparent;
          border: 1px solid ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.white};

          &:hover {
            background-color: ${({ theme }) => theme.colors.white};
            color: #111;
          }
        `;
      case "light":
        return css`
          background-color: ${({ theme }) => theme.colors.white};
          border: 1px solid ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.black};
        `;
      case "slim":
        return css`
          color: ${({ theme }) => theme.colors.grey};
          text-transform: uppercase;
        `;
      default:
        return css`
          background-color: ${({ theme }) => theme.colors.primary};
          color: ${({ theme }) => theme.colors.white};
        `;
    }
  }}
`;

export default Button;

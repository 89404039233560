import React from "react";
import { Link } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { Button } from "./";
import { Flex } from "../layout";

const HomeButton = () => {
  return (
    <Flex justifyContent="center" alignItems="center" height="calc(5rem + 3vw)">
      <Button as={Link} to="/" type="slim" icon={ArrowLeft} fontSize="1.2rem">
        Back Home
      </Button>
    </Flex>
  );
};

export default HomeButton;

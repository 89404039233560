import React from "react";
import styled from "styled-components";
import { Container, Flex } from "../layout";
import { Linkedin, GitHub, Mail } from "react-feather";

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <Flex className="links" alignItems="center" justifyContent="center">
          <a
            href="https://github.com/connorbiddle"
            target="_blank"
            rel="noreferrer"
          >
            <GitHub size={28} />
          </a>
          <a
            href="https://www.linkedin.com/in/connor-biddle"
            target="_blank"
            rel="noreferrer"
          >
            <Linkedin size={28} />
          </a>
          <a href="mailto:connor.biddle@hotmail.com">
            <Mail size={28} />
          </a>
        </Flex>
      </Container>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  padding: 2.5rem;
  margin-top: auto;

  .links a {
    color: ${({ theme }) => theme.colors.black};
    padding: 0.5rem;
    margin: 0 0.5rem;
    transition: color 250ms ease;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export default Footer;

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  .App {
    max-width: min(100vw, 100%);
    min-height: 100vh;
    overflow-x: hidden;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: "Raleway", sans-serif;
    max-width: 100vw;
    overflow-x: hidden;
  }

  input, button, textarea {
    font-family: inherit;
  }

  img {
    max-width: 100%;
  }

  p {
    margin-bottom: 1.25rem;
    font-size: 1.1rem;
    line-height: 1.9;
  }

  p a {
    color: inherit;
    text-decoration: none;
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    transition: color 250ms ease;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${() => {
    const headings = [1, 2, 3, 4, 5, 6];
    return headings
      .map(
        h =>
          `h${h} {
            font-size: calc(2rem - 0.${h}rem);
            margin-bottom: calc(1rem + 0.${h}rem);
          }`
      )
      .join("");
  }}
`;

export default GlobalStyle;

import React from "react";
import { Element } from "react-scroll";
import Container from "../layout/Container";
import ProjectPreview from "../utility/ProjectPreview";

const Projects = ({ projects }) => {
  return (
    <Container as={Element} name="projects">
      {projects ? (
        <div>
          {projects.map(project => (
            <ProjectPreview project={project} key={project.sys.id} />
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </Container>
  );
};

export default Projects;

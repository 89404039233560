import React from "react";
import { Container, Page } from "../layout";
import { HomeButton } from "../utility";
import { ProjectContent } from "../sections";

const Project = ({ project }) => {
  const pageTitle = project
    ? `${project.fields.title} | Connor Biddle`
    : "Connor Biddle | Front-End Web Developer";

  return (
    <Page pageTitle={pageTitle}>
      <Container narrow>
        <HomeButton />
        {project ? <ProjectContent project={project} /> : "No project found!"}
      </Container>
    </Page>
  );
};

export default Project;

import React from "react";
import styled, { css } from "styled-components";

const Container = props => {
  return <StyledContainer {...props}>{props.children}</StyledContainer>;
};

const StyledContainer = styled.div`
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 100%;
  width: ${({ narrow }) => (narrow ? "800px" : "1300px")};

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.md}) {
      width: 550px;
    }
  `}

  ${({ narrowOnMobile }) =>
    narrowOnMobile &&
    css`
      @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        width: 800px;
      }
    `}
`;

export default Container;
